import '../sass/pages/company.scoped.scss';
import React from 'react';
import Seo from '../components/Seo';
import Layout from '../components/blocks/Layout';
import Logo from '../icons/logo.svg';
import { ModalProvider } from '../context/ModalContext';

const CompanyWrapper = () => {
  return (
    <ModalProvider>
      <Company />
    </ModalProvider>
  );
};

const Company = () => {
  return (
    <Layout footerTitle='Мы находимся по адресу'>
      <Seo title='О компании' />
      <h1>О компании</h1>
      <div className='content'>
        <section className="about">
          <Logo className='logo' />
          <div className="about-text">
            <h2 className='company-name'>Invest Group Company</h2>
            <h3 className='title'>Вентиляция и кондиционирование</h3>
            <p>
              Компания разрабатывает, проектирует и реализует современные системы
              вентиляции, кондиционирования, увлажнения любой сложности. Мы — надежный
              партнер. Вы всегда можете получить нужную Вам продукцию в кратчайший
              срок на одном из наших складов в Караганде, Астане, Алматы и Шымкенте.
              Все сертификаты качества соответствия ГОСТ и международными показателями
              качества ISO.
            </p>
          </div>
        </section>
        <section className="equipment">
          <h4 className='equipment-title'>Широкий спектр предлагаемого оборудования</h4>
          <div className="brands">
            <span><strong>Ровен</strong> – Вентиляторы различного назначения и исполнения, оборудование для систем вентиляции, клапаны воздушные, приточные установки, анемостаты, фильтры, дымоудаления и огнезадерживающие.</span>
            <span><strong>NED</strong> – Центральные кондиционеры, приточные и вытяжные установки, ККБ, чиллеры, фанкойлы, прецизионные кондиционеры.</span>
            <span><strong>LESSAR</strong> – Мультисплит-системы, сплит-системы, бытовые и полупромышленные, фанкойлы, VRF-системы, прециционные кондиционеры, руфтопы.</span>
            <span><strong>Hidros</strong> – Осушители промышленные, чиллеры.</span>
            <span><strong>Тепломаш</strong> – Тепловые завесы и пушки.</span>
            <span><strong>ActionClima</strong> – Фанкойлы премиум класса.</span>
          </div>
        </section>
      </div>
    </Layout>
  )
};

export default CompanyWrapper;
